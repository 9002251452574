
import { default as common } from '@/config/common.json'
import AgentDropdown from '@bd/admin/components/AgentDropdown/AgentDropdown.vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useAppStore } from '@bd/admin/store'
import { AgentContent, OfferContent } from '@bd/admin/types'
import { useSelectedAgent } from '@bd/admin/use/useSelectedAgent'
import {
  Avatar,
  DashboardCard,
  FiltersSidebar,
  Loader,
  UserType,
  InfiniteScroll,
  useComputedActiveFilters,
  determinePageSize,
  useComputedFilters,
  Svg,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { FilterProp } from '@bd/store-modules/types'
import { computed, defineComponent, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { adminApi } from '@bd/api'
import { AdminOfferRowDto } from '@bd/api/admin-api/types'

export default defineComponent({
  name: 'Offers',
  components: {
    AppContentLayout,
    DashboardCard,
    AgentDropdown,
    FiltersSidebar,
    EmptyState,
    Loader,
    InfiniteScroll: InfiniteScroll.InfiniteScroll,
    Svg,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()

    const filtersState = computed(() => {
      return store.state.offers?.filters
    })

    const citiesAndProvinces = computed(() => {
      return store.state.offers?.citiesAndProvinces.content?.content || []
    })

    const activeFilters = computed(() => {
      return useComputedActiveFilters(store.state.offers?.searchedFilters || {})
    })

    const offersState = store.state.offers

    const { navigationWidth } = common

    const pageNumber = computed(() => {
      if (!offersState?.offers) {
        return 0
      }
      return offersState?.offers.pageNumber
    })

    const filterResults = () => {
      if (store.state.offers?.searchedFilters !== filtersState.value) {
        store.commit('offers/APPLY_FILTERS')
      }
    }

    const loadNextPage = async () => {
      const pageIndex = pageNumber.value + 1
      await store.dispatch('offers/setFilterAndRefresh', { pageIndex })
    }

    const generateAvatar = (item: OfferContent): Avatar => {
      return {
        userType: UserType.Seller,
        user: {
          firstName: item.sellerFirstName,
          lastName: item.sellerLastName,
        },
      }
    }

    const addToQueue = (queueObj: FilterProp) => {
      if (!queueObj?.prop) {
        return
      }
      const { prop, value } = queueObj
      store.dispatch('offers/setFilters', {
        [prop]: value,
        pageIndex: 0,
      })
      if (
        prop.includes('cityIds') &&
        filtersState.value?.cityIds?.length === 1
      ) {
        store.dispatch('offers/cityDistricts', filtersState.value?.cityIds[0])
      }
    }

    const offers = computed(() => offersState?.offers.content || [])

    const stateFilters = computed(() => store.state?.offers?.filters)
    const districts = computed(() => store.state.offers?.districts)

    const { filters } = useComputedFilters(stateFilters, districts)
    const isEmptyOfferList = computed(() => {
      if (!offersState) {
        return true
      }
      return offersState.offers.content.length <= 0
    })

    const fetchAgents = async () => {
      await store.dispatch('agents/agentList')
    }
    const fetchCities = async () => {
      await store.dispatch('offers/citiesAndProvincesList')
    }

    const goToOfferDetails = (offerId: number) => {
      router.push({ name: 'OfferUpdate', params: { id: offerId } })
    }

    const isSidebarActive = ref(false)

    const toggleSidebar = () => {
      isSidebarActive.value = !isSidebarActive.value
    }

    const onAddOfferClick = () => {
      router.push({ path: '/offers/add' })
    }
    const displayClearFilterButton = computed(() => {
      if (!offersState) {
        return false
      }
      const {
        showDrafts,
        pageSize,
        pageIndex,
        agentId,
        ...otherFilters
      } = offersState.filters
      return Object.keys(otherFilters).length > 0
    })

    const clearFilters = async () => {
      await store.dispatch('offers/clearFilterAndRefresh', {
        keep: ['agentId'],
      })
    }

    const agents = computed(() => store.state.agents?.agents.content || [])

    const selectedAgent = useSelectedAgent(() =>
      agents.value.find(
        (content: AgentContent) =>
          content.id === store.state.offers?.filters.agentId,
      ),
    )

    const selectedAgentHandle = async (agentId?: number) => {
      await store.dispatch('offers/setFilterAndRefresh', { agentId })
    }

    const isLoading = shallowRef(false)

    // not reactive on purpose. InfiniteScroll component has internal logic that
    // adjusts number of pages fetched in parallel depending on container size.
    const pageSize = determinePageSize()

    const getOffersPage = computed<InfiniteScroll.GetPageFn>(() => {
      const filters = store.state.offers?.searchedFilters
      return async (page) => {
        const { data } = await adminApi.offers({ ...filters, ...page })
        return data
      }
    })

    const getOfferId = (offer: AdminOfferRowDto) => {
      return offer.id
    }

    const selectedCities = computed(() => {
      const cityFilter = activeFilters.value.find(
        ({ filterName }) => filterName === 'city',
      )
      return cityFilter?.passedData[0].value
    })

    const searchDistricts = async (cityId: number) => {
      await store.dispatch('offers/cityDistricts', cityId)
    }

    watch(selectedCities, (value) => {
      if (value && value.length === 1) {
        searchDistricts(+value[0])
      }
    })

    fetchAgents()
    fetchCities()

    return {
      t,
      navigationWidth,
      pageSize,
      loadNextPage,
      generateAvatar,
      agents,
      offers,
      isEmptyOfferList,
      goToOfferDetails,
      isSidebarActive,
      toggleSidebar,
      displayClearFilterButton,
      clearFilters,
      onAddOfferClick,
      filters,
      citiesAndProvinces,
      addToQueue,
      filterResults,
      districts,
      selectedAgent,
      selectedAgentHandle,
      getOffersPage,
      getOfferId,
      filtersState,
      isLoading,
    }
  },
})
